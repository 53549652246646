import { template as template_176a0c5a09bd4ee19ed58fc0ab39aae1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_176a0c5a09bd4ee19ed58fc0ab39aae1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
