import { template as template_38d6fb417ea041e383256eb9f5fb8c2d } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllTagsSectionLink = template_38d6fb417ea041e383256eb9f5fb8c2d(`
  <SectionLink
    @linkName="all-tags"
    @content={{i18n "sidebar.all_tags"}}
    @route="tags"
    @prefixType="icon"
    @prefixValue="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllTagsSectionLink;
